<template>
  <div>
    <div class="mt-10 text-sm text-gray-400">Email</div>
    <el-input class="w-80" v-model="email" />
    <div class="mt-10 text-sm text-gray-400">Company</div>
    <el-input class="w-80" v-model="company" />

    <br />
    <el-button type="primary" @click="doLogin">Login</el-button>
  </div>
</template>

<script>
import authService from '@/services/authService';
export default {
  name: 'StagingLoginComponent',
  data() {
    return {
      email: 'testuser@gmail.com',
      company: 'test_company_id_123'
    };
  },
  methods: {
    async doLogin() {
      try {
        let response = await authService.stagingLogin({
          email: this.email,
          companyId: this.company
        });
        window.localStorage.setItem('grw-token', response.data.raw);
        location.href = '/';
      } catch (err) {
        this.errorToast('Failed');
      }
    }
  },

  mounted() {
    console.log('Yes mounted');
  }
};
</script>
